import { numberAfterPoint } from './numberAfterPoint';
import { numberWithCommas } from './thousandsSeparator';

export const renderPrice = (data) => {
  if (data?.price) {
    return numberAfterPoint(data.price) === 0
      ? `${numberWithCommas(data.price)},00 €`
      : `${numberWithCommas(data.price.toFixed(2))} €`;
  }
  return '0 €';
};

export const renderCardPrice = (data) => {
  if (data) {
    return numberAfterPoint(data) === 0
      ? `${numberWithCommas(data)} €`
      : `${numberWithCommas(data.toFixed(0))} €`;
  }
  return '0 €';
};

export const renderArea = (data) => {
  if (data) {
    return numberAfterPoint(data) === 0
      ? `${numberWithCommas(data)},00 m²`
      : `${numberWithCommas(data.toFixed(2))} m²`;
  }
  return '0 m²';
};

export const renderCardLivingSpace = (livingSpace) => {
  return `${livingSpace ? livingSpace.toFixed(0) : 0} m²`;
};

export const extendOptionsWithNoData = (options) => {
  // Check if all series data arrays are empty
  const allDataEmpty = options.series.every(
    (series) => series.data.length === 0,
  );

  if (allDataEmpty) {
    options.lang = {
      noData: 'In diesem Gebiet sind leider nicht ausreichend Daten vorhanden', // Custom message for no data
      ...options.lang,
    };

    options.noData = {
      ...options.noData,
    };
  }

  return options;
};
